@font-face {
  font-family: "iconfont"; /* Project id  */
  src: url('iconfont.ttf?t=1671546556383') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mima:before {
  content: "\e60f";
}

.icon-wode-wode:before {
  content: "\e60d";
}

.icon-shoujihao:before {
  content: "\e61f";
}

.icon-yanjing_yincang:before {
  content: "\eb45";
}

.icon-yulan:before {
  content: "\e62b";
}

