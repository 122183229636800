@import './style/normalize.css';
@import './font/iconfont.css';

.App {
  padding-top: 50px;
}

.main-content {
  padding: 0 20px;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
}