.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 135px;
  overflow: hidden;
  background: #222;
  color: #aaaaaa;
  padding: 25px 90px;
  font-size: 0rem;

  &-left {
    font-size: 14px;
    color: #ddd;

    &_name {
      font-size: 24px;
      color: #fff;
      margin-bottom: 5px;
    }

    &_copy {
      margin-top: 15px;
    }

    &_mail {
      color: #ddd;
      margin-right: 6px;

      &:hover {
        color: #fff;
      }
    }
  }

  &_go-top {
    position: fixed;
    right: 10px;
    bottom: 100px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #666;
    border-radius: 50%;
    font-size: 16px;
    color: #eee;
    cursor: pointer;
    z-index: 999;

    &:hover {
      color: #fff;
    }
  }
}