.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  max-width: 100vw;
  padding: 0 15px;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 50px;
  background: #e7e7e7;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 1px #ccc;

  &-logo {
    margin-left: 100px;
    height: 100%;
    width: 100px;
    background: url(../../asset//icon/logo.png) no-repeat;
    background-size: contain;
  }

  &-menus {
    height: 100%;
    display: flex;
    font-weight: bold;

    &_item {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: #333;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      margin-right: 1px;

      &:hover, &.active {
        background: #fff;
      }
    }
  }

  &-cart {
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    position: relative;
    background: #BDBDBD;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: #fff;

      .header-cart_list {
        display: block;
      }
    }

    &_icon {
      margin-right: 10px;
    }

    &_list {
      display: none;
      width: 300px;
      padding: 15px;
      position: absolute;
      right: 0;
      top: 100%;
      background: #fff;
      font-size: 13px;
      
      &-good {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &-img {
          width: 50px;
          height: 50px;
          background: #e7e7e7;
          border-radius: 6px;
        }

        &-title {
          flex: 1;
          margin-left: 10px;
        }
      }
    }
  }
}